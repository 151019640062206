@font-face {
  font-family: "PaytoneOne";
  src: local("PaytoneOne"),
    url("./assets/fonts/PaytoneOne.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/fonts/Roboto.ttf") format("truetype");
}

:root {
  --giveit-blue: #384ff0;
  --giveit-blueDarker: #384be0;
  --giveit-white: #ffffff;
  --giveit-whiteDarker: #eeeeee;
  --giveit-green: #64af13;
  --giveit-greenDarker: #61a913;

  --giveit-title-font: "PaytoneOne", sans-serif;
  --giveit-regular-font: "Roboto", sans-serif;
}

.welcomeContainer {
  background-color: var(--giveit-blue);

  .readMore {
    font-family: var(--giveit-regular-font);
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;

    display: flex;
    align-items: center;
    gap: 15px;

    width: fit-content;
    border-radius: 15px;

    color: var(--giveit-blue);
    background-color: #f0f0f0;
    transition: 0.1s;
    &:hover {
      background-color: #e0e0e0;
    }

    cursor: pointer;

    img.rotate {
      transform: rotate(180deg);
    }
  }

  .homeContainer {
    background-color: var(--giveit-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .homeContent {
      color: var(--giveit-white);
      width: 60%;
      padding-left: 33px;

      display: flex;
      flex-direction: column;

      h1 {
        font-family: var(--giveit-title-font);
        font-weight: 400;
        font-size: 40px;

        span {
          color: #ffb946;
        }
      }

      p {
        font-family: var(--giveit-regular-font);
        font-size: 20px;
      }

      .homeButtonsContainer {
        padding-top: 15px;
        display: flex;
        gap: 25px;
      }
    }

    img {
      width: 40vw;
      min-width: 600px;
      max-width: 700px;
    }
  }

  @media (max-width: 700px) {
    .homeContainer {
      height: 400px;
      .homeContent {
        width: 90%;

        h1 {
          font-size: 30px;
        }

        p {
          font-size: 16px;
        }
      }

      img {
        display: none;
      }
    }
  }

  .waveDivider {
    position: relative;
    height: 102px;

    img {
      position: absolute;
      bottom: 0px;
      width: 100%;
    }
    img.rotate {
      top: 0px;
      transform: rotate(180deg);
    }
  }

  .waveDivider.wave2 {
    background-color: var(--giveit-white);
    top: -45px;
    height: 140px;
  }

  .waveDivider.rotate {
    top: 0px;
    background-color: white;
  }

  @media (max-width: 700px) {
    .waveDivider {
      height: 50px;

      img {
        height: auto;
      }
    }
  }

  .lineBefore {
    width: 1px;
    height: 100%;
    left: 30px;
    top: 0;
    border-left: 2px solid #cecece;
    position: absolute;
    z-index: 1;
  }

  .circleLineContainer {
    background-color: #e9e9e9;
    width: 45px;
    height: 45px;
    border-radius: 27px;
    position: absolute;
    left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .circleLineInside {
      background-color: var(--giveit-blue);
      width: 21px;
      height: 21px;
      border-radius: 12px;
    }
  }

  .stepContainer {
    position: relative;
    margin-left: 80px;
    top: -20px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    .stepNumber {
      font-family: var(--giveit-regular-font);
      font-size: 70px;
      font-weight: bold;
      color: #eaecfb;
    }

    .stepLabel {
      font-family: var(--giveit-regular-font);
      color: var(--giveit-blue);
      font-weight: bold;
      background-color: #f0f0f0;
      width: fit-content;
      padding: 10px 20px;
      border-radius: 15px;
    }
  }

  .doYouKnowContainer {
    background-color: var(--giveit-white);
    display: flex;
    justify-content: center;
  }

  .doYouKnowContent {
    display: flex;
    margin-top: 50px;

    img {
      position: relative;
      top: -140px;
    }

    .doYouKnowTextContainer {
      max-width: 400px;
      .doYouKnowTitle {
        color: var(--giveit-blue);
        font-family: var(--giveit-title-font);
        font-size: 25px;
      }

      .doYouKnowText {
        font-family: var(--giveit-regular-font);
        font-size: 18px;
      }

      .doYouKnowAquaMark {
        font-family: var(--giveit-regular-font);
        color: #686868;
        font-size: 13px;
      }
    }
  }

  @media (max-width: 700px) {
    .doYouKnowContent {
      flex-direction: column;
      margin: 0px;
      img {
        top: -50px;
        margin: 0 auto;
        width: 150px;
      }

      .doYouKnowTextContainer {
        margin-left: 55px;
      }
    }
  }

  section {
    margin-left: 80px;
    .sectionTitle {
      color: var(--giveit-blue);
      font-family: var(--giveit-title-font);
      font-size: 35px;
    }

    .sectionTitle.sectionTitleWhite {
      color: var(--giveit-white);
    }

    p {
      font-family: var(--giveit-regular-font);
      font-size: 18px;
      line-height: 25px;
    }

    p.white {
      color: var(--giveit-white);
    }
  }

  .aboutUsContainer {
    position: relative;
    background-color: var(--giveit-white);

    .aboutUsContent {
      display: flex;
      .aboutUsTextContainer {
        width: 50%;
      }
      .imageContainer {
        display: flex;
        justify-content: center;
        padding-left: 25px;
        width: 45%;
        height: 100%;
        img {
          position: relative;
          top: -50px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 700px) {
    .aboutUsContainer {
      padding-top: 35px;

      .aboutUsContent {
        flex-direction: column;

        .aboutUsTextContainer {
          width: 95%;
        }

        .imageContainer {
          width: 90%;
          padding-left: 0px;

          img {
            top: 0px;
          }
        }
      }
    }
  }

  .ourServicesContainer {
    position: relative;
    background-color: var(--giveit-white);
    padding-top: 50px;

    .ourServicesContent {
      .ourServicesFlexContainer {
        display: flex;
        .ourServicesTextContainer {
          width: 50%;
        }
        .imageContainer {
          padding-left: 25px;
          width: 50%;
          display: flex;
          justify-content: center;
          height: 0px;

          img {
            position: relative;
            top: -220px;
            width: 400px;
            height: 450px;
          }
        }
      }
      .ourServicesCardsContainer {
        margin-top: 35px;
        padding-bottom: 100px;

        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        align-items: baseline;

        .ourServicesCard {
          box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.17);
          padding: 32px 24px;
          min-width: 288px;
          max-width: 288px;

          h2 {
            font-family: var(--giveit-title-font);
            font-weight: 500;
          }

          p {
            font-family: var(--giveit-regular-font);
          }

          .readMore {
            font-family: var(--giveit-regular-font);
            padding: 10px;
            font-weight: 500;
            font-size: 14px;

            display: flex;
            align-items: center;
            gap: 15px;

            width: fit-content;
            border-radius: 15px;

            color: var(--giveit-blue);
            background-color: #f0f0f0;
            transition: 0.1s;
            &:hover {
              background-color: #e0e0e0;
            }

            cursor: pointer;

            img.rotate {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    .ourServicesContainer {
      padding-top: 100px;
      .ourServicesContent {
        .ourServicesFlexContainer {
          flex-direction: column;

          .ourServicesTextContainer {
            width: 95%;
          }

          .imageContainer {
            width: 90%;
            padding-left: 0px;
            height: 0px;
            display: none;

            img {
              top: -80px;
            }
          }
        }

        .ourServicesCardsContainer {
          margin-top: 0px;
          flex-wrap: nowrap;
          margin-bottom: 20px;
          overflow-x: scroll;

          margin-left: -30px;
          padding-right: 40px;

          .ourServicesCard {
            display: flex;
            flex-direction: column;

            margin-top: 25px;
            margin-bottom: 25px;
            margin-left: 10px;

            padding: 10px 24px;

            min-width: 200px;
            max-width: 200px;

            img {
              width: 44px;
            }

            h2 {
              font-size: 18px;
              margin-bottom: -2px;
            }

            p {
              font-size: 14px;
            }

            .readMore {
              font-size: 12px;
              img {
                width: 12px;
              }
            }
          }
        }
      }
    }
  }

  .ourBenefitsContainer {
    padding-top: 100px;
    position: relative;

    .ourBenefitsPlanetsImage {
      position: absolute;
      right: 0px;
      top: -200px;
      mix-blend-mode: multiply;
    }

    .ourBenefitsAstronautImage {
      position: absolute;
      right: 100px;
      width: 200px;
      top: 80px;
    }

    .ourBenefitsContent {
      .ourBenefitsTextContainer {
        width: 70%;
      }
    }

    .ourBenefitsCardsContainer {
      padding-bottom: 100px;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      .ourBenefitsCard {
        margin-top: 100px;
        min-width: 250px;
        max-width: 250px;
        min-height: 200px;
        max-height: 200px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: var(--giveit-title-font);
        color: var(--giveit-white);
        text-align: center;

        border-radius: 20px;

        h3 {
          font-weight: 400;
          font-size: 20px;
          width: 90%;
        }

        img {
          position: absolute;
          top: -50px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    .ourBenefitsContainer {
      .ourBenefitsAstronautImage {
        display: none;
      }

      .ourBenefitsCardsContainer {
        margin-left: -45px;
        .ourBenefitsCard {
          min-width: 150px;
          max-width: 150px;
          min-height: 150px;
          max-height: 150px;

          img {
            width: 75px;
            top: -35px;
          }

          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }

  .moreContainer {
    padding-top: 50px;
    position: relative;

    .morePlanetsImage {
      position: absolute;
      right: 0px;
      top: -300px;
      z-index: 1;
      mix-blend-mode: multiply;
    }

    .moreTextContainer {
      width: 60%;
      max-width: 780px;
    }

    .moreAstronautImage {
      position: absolute;
      right: 12%;
      top: 52px;
    }

    .moreCardContainer {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 15px;
      padding-top: 50px;
      padding-bottom: 90px;

      .moreCard {
        z-index: 2;
        font-family: var(--giveit-regular-font);
        color: var(--giveit-white);
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #000;

        width: 200px;
        height: 600px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        border-radius: 15px;
        padding: 0px 66px;

        h4 {
          font-family: var(--giveit-title-font);
          font-weight: 400;
          font-size: 25px;
          line-height: 5px;
        }

        span {
          opacity: 0.8;
        }
      }
    }
  }

  @media (max-width: 700px) {
    .moreContainer {
      .moreTextContainer {
        width: 90%;
      }

      .moreAstronautImage {
        display: none;
      }

      .moreCardContainer {
        flex-direction: column;
        margin-left: -30px;
        gap: 25px;

        .moreCard {
          width: 60%;
          height: 300px;
          img {
            width: 70px;
          }
        }
      }
    }
  }

  .pricesContainer {
    background-color: white;
    padding-top: 100px;
    position: relative;
    .pricesContent {
      .pricesTextContainer {
        p {
          width: 60%;
        }
      }

      .priceCardsContainer {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 50px;
        flex-wrap: wrap;
        .priceCard {
          box-shadow: 0px 1px 2px rgba(85, 105, 135, 0.1);
          border-radius: 6px;
          min-width: 350px;
          max-width: 350px;

          h2 {
            font-family: var(--giveit-title-font);
            color: var(--giveit-blue);
            font-size: 50px;
          }

          span {
            font-family: var(--giveit-regular-font);
            color: #8896ab;
          }

          ul {
            font-family: var(--giveit-regular-font);
            display: flex;
            flex-direction: column;
            gap: 10px;

            li {
              list-style: none;
              display: flex;
              align-items: center;
              margin-left: -40px;
              gap: 10px;
            }

            li.disabled {
              color: #cccccc;
            }
          }

          button {
            font-family: var(--giveit-regular-font);
            font-weight: bold;
            color: white;
            box-shadow: 0px 1px 3px rgba(42, 51, 66, 0.06),
              0px 1px 2px rgba(42, 51, 66, 0.03);
            border-radius: 6px;
            padding: 16px 28px;
            width: 100%;
            border: 0px;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    .pricesContainer {
      .pricesContent {
        .pricesTextContainer {
          p {
            width: 95%;
          }
        }

        .priceCardsContainer {
          display: flex;
          flex-wrap: wrap;
          margin-left: -35px;
        }
      }
    }
  }

  .contactContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 35px;
    padding-bottom: 50px;
    .formContainer {
      background: #ffffff;
      box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.17);
      border-radius: 20px;
      padding: 15px 40px;

      width: 50%;

      span {
        font-family: var(--giveit-title-font);
        color: var(--giveit-blue);
        font-size: 28px;
      }

      p {
        font-size: 16px;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 25px;

        div {
          position: relative;
          font-family: var(--giveit-regular-font);

          label {
            position: absolute;
            top: -8px;
            left: 17px;
            background-color: #ffffff;
            font-weight: bold;

            span {
              font-size: 14px;
              color: red;
            }
          }

          input {
            background: #ffffff;
            border: 1px solid #d2d5da;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            padding: 15px;
            width: 97%;
          }

          input:focus {
            outline: 2px solid var(--giveit-blue);
          }

          textarea {
            background: #ffffff;
            border: 1px solid #d2d5da;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            padding: 15px;
            width: 97%;
          }

          textarea:focus {
            outline: 2px solid var(--giveit-blue);
          }
        }

        button {
          background: var(--giveit-blue);
          border-radius: 8px;
          font-family: var(--giveit-regular-font);
          padding: 20px;
          color: #fff;
          font-weight: 400;
          letter-spacing: 2px;
          border: 0px;
          transition: 0.1s;
          &:hover {
            background-color: var(--giveit-blueDarker);
          }
        }
      }
    }

    .contactChannelsContainer {
      display: flex;
      justify-content: space-around;
      align-items: start;
      flex-direction: column;
      gap: 25px;
      width: 40%;

      .contactChannelContent {
        display: flex;
        padding-left: 20%;
        gap: 15px;

        .contactChannelInfo {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .contactChannelTitle {
            font-size: 25px;
            font-family: var(--giveit-title-font);
            color: var(--giveit-blue);
          }

          .contactChannelValue {
            font-size: 16px;
            font-family: var(--giveit-regular-font);
            color: #8896ab;
          }
        }
      }
    }

    @media (max-width: 700px) {
      .contactContainer {
        width: 100%;
      }

      .formContainer {
        width: 100%;
      }

      .contactChannelsContainer {
        margin-top: 25px;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }

  footer {
    background-color: var(--giveit-blue);
    padding-top: 50px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 50px;

    .footerLogoContainer {
      display: flex;
      align-items: center;
      font-family: var(--giveit-title-font);
      color: white;
      letter-spacing: 2px;
      font-weight: 100;
      font-size: 15px;

      img {
        width: 60px;
      }
    }

    .footerGiveIt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;
    }

    @media (max-width: 700px) {
      .footerGiveIt {
        font-size: 14px;
      }
    }

    .footerOptionContainer {
      display: flex;
      gap: 25px;
      .footerOption {
        cursor: pointer;
        color: #9ea9b9;
        text-decoration: none;
        font-family: var(--giveit-regular-font);
      }
    }

    .copyRight {
      color: #9ea9b9;
      font-family: var(--giveit-regular-font);
      font-size: 14px;
    }
  }
}
