.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 33px 0px 33px;
  .navbarLogoContainer {
    display: flex;
    align-items: center;

    img {
      height: 50px;
    }

    h1 {
      color: var(--giveit-white);
      font-family: var(--giveit-title-font);
      font-weight: 500;
    }
  }

  .navbarOptionsContainer {
    display: flex;
    gap: 20px;
    .navbarOption {
      cursor: pointer;
      color: var(--giveit-white);
      font-family: var(--giveit-regular-font);
      font-weight: 500;
      text-decoration: none;
      transition: 0.1s;
      border-radius: 30px;
      padding: 3px 8px;
      &:hover {
        background-color: var(--giveit-blueDarker);
      }
    }
  }

  @media (max-width: 700px) {
    .navbarOptionsContainer {
      display: none;
    }

    .navbarLogoContainer {
      img {
        height: 30px;
      }

      h1 {
        font-size: 20px;
      }
    }
  }
}
