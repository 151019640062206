.buttonContainer {
  font-family: var(--giveit-regular-font);
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 15px 40px;
  transition: 0.1s;

  @media (max-width: 700px) {
    font-size: 12px;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.buttonGreen {
  color: var(--giveit-white);
  background-color: var(--giveit-green);
  &:hover {
    background-color: var(--giveit-greenDarker);
  }
}

.buttonWhite {
  color: var(--giveit-blue);
  background-color: var(--giveit-white);
  &:hover {
    background-color: var(--giveit-whiteDarker);
  }
}

.buttonBlue {
  color: var(--giveit-white);
  background-color: var(--giveit-blue);
  border: 1.5px solid var(--giveit-white);
  &:hover {
    background-color: var(--giveit-blueDarker);
  }
}

.buttonRoundedLeft {
  border-radius: 30px 30px 0px 30px;
}

.buttonRoundedRight {
  border-radius: 30px 30px 30px 0px;
}

.buttonBordered {
}
